<template>
    <projeto-form acao="ATUALIZAR" @salvar="atualizar($event)" :cancelar="cancelar" :erros="erros" :projeto="projeto"></projeto-form>
</template>

<script>
import ProjetosServices from './services';
import ProjetoForm from './ProjetoForm';

export default {
    components: {
        ProjetoForm,
    },

    data() {
        return {
            erros: [],
            projeto: null,
            atualizado: false,
        };
    },

    methods: {
        cancelar() {
            if (this.atualizado) {
                this.$store.dispatch('setAtualizar', true);
            }
            this.$router.push({
                name: 'Projetos_Detalhar',
                params: {
                    id: this.$route.params.id,
                },
            });
        },

        obterProjeto() {
            this.$store.dispatch('addRequest');
            ProjetosServices.obterProjetoPorId(this.$route.params.id).then((response) => {
                if (response && response.success) {
                    this.projeto = response.data;
                } else {
                    this.projeto = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar(projetoAtualizado) {
            this.$store.dispatch('addRequest');
            let projetoAtualizadoDto = {
                projetoId: this.$route.params.id,
                cnpj: projetoAtualizado.cnpj,
                nome: projetoAtualizado.nome,
                descricao: projetoAtualizado.descricao,
                tipo: projetoAtualizado.tipo,
                observacoes: projetoAtualizado.observacoes,
            };
            ProjetosServices.atualizarProjeto(projetoAtualizadoDto).then((response) => {
                if (response && response.success) {
                    this.projeto = response.data;
                    this.atualizado = true;
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Projeto',
                        detail: 'Projeto atualizado com sucesso',
                        life: 3000,
                    });
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        this.obterProjeto();
    },
};
</script>
